import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux'
import store from '../store'
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '@components/theme';
import CookieConsent from "react-cookie-consent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@components/Link"
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from "next/router";
import i18n from 'i18next';
import { initReactI18next, I18nextProvider, useTranslation } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { CookiesProvider } from "react-cookie";
import SmartBanner from "@components/SmartBanner"
import moment from 'moment';
import "moment/locale/el";
import "moment/locale/en-gb"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles/supportedChargres.css";
import "styles/supportedChargres.css"
import LogRocket from "logrocket";
import setupLogRocketReact from 'logrocket-react';
import { IS_TEST_ENV } from '@js-shared/lib/config/environment';


// Do not load cookies, tidio and other scripts in these paths ()
const PATH_EXCEPTIONS = [
  "/complete-payment-session"
]

const isServer = typeof window === "undefined";

if (!isServer) {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'en',
      debug: false,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      load: 'languageOnly',
    });

    if (!IS_TEST_ENV) {
      LogRocket.init('ygaw0m/evloader-nextjs');
      // plugins should also only be initialized when in the browser
      setupLogRocketReact(LogRocket);
    }
}

export default function App(props) {
  const { Component, pageProps } = props;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const AppWithoutSuspense = () => {
    // The i18n object below is different from the i18n imported object, due to
    // different scope!
    const { t, i18n } = useTranslation();
    const router = useRouter();
    const [shouldLoadEverything, setShouldLoadEverything] = useState(false);

    useEffect(() => {
      let isAllowedPath = true;
      PATH_EXCEPTIONS.forEach(path => {
        if (router.asPath.includes(path)) isAllowedPath = false;
      })
      setShouldLoadEverything(isAllowedPath)
    }, []);

    useEffect(() => {
      const language = i18n.language.split("-")[0]
      moment.locale(language);
    }, [i18n.language]);

    return (
      <ThemeProvider theme={theme}>
        <Head>
          <title>EV Loader</title>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        </Head>

        <Provider store={store}>
          <CookiesProvider>
            {/* <React.StrictMode> */}
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />

              <SmartBanner/>

              {shouldLoadEverything && (
                <>
                  <Script
                    strategy="beforeInteractive"
                    src="https://www.googletagmanager.com/gtag/js?id=G-D8K0XMP7J5"
                  />
                  <Script
                    id="gtag-init"
                    strategy="beforeInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                    
                      gtag('config', 'G-D8K0XMP7J5', {
                        page_path: window.location.pathname,
                      });`
                    }}
                  />
                  <Script
                    strategy="afterInteractive" 
                    src={`https://maps.google.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_PLACES_API_PK}&libraries=places`}
                  />
                  <Script 
                    strategy="afterInteractive" 
                    src="//code.tidio.co/pudm4qv4azy9lzmhbzknmo8btidi6koj.js"
                  />

                  <CookieConsent
                    location="bottom"
                    buttonText={t('Αποδοχή')}
                    declineButtonText={t('Απόρριψη')}
                    declineCookieValue
                    cookieName="cookies"
                    style={{ 
                      background: "#222222",
                      zIndex: 2000
                    }}
                    buttonStyle={{
                      color: "#fafafa",
                      fontSize: "13px",
                      backgroundColor: "#f18d0f",
                      padding: theme.spacing(1),
                      borderRadius: "6px",
                      marginRight: theme.spacing(75)
                    }}
                    expires={150}
                  >
                    <Box display="flex" justifyContent="flex-right">
                      <Typography variant="body1">
                        {t('Η ιστοσελίδα μας χρησιμοποιεί cookies για να βελτιώσει την εμπειρία περιήγησής σας')}
                      </Typography>
                      <Link
                        href="/privacy"
                        color="primary"
                        style={{ marginLeft: "15px", textDecoration: "none" }}
                        naked
                      >
                        <Typography variant="subtitle1" style={{ color: "rgb(139 139 139)" }}>
                          {t('Πολιτική Απορρήτου')}
                        </Typography>
                      </Link>
                    </Box>
                  </CookieConsent>
                </>
              )}

              <Component {...pageProps} />
            {/* </React.StrictMode> */}
          </CookiesProvider>
        </Provider>
      </ThemeProvider>
    )
  }

  if (!isServer) {
    return (
      <React.Suspense fallback={<div></div>}>
        <I18nextProvider i18n={i18n}>
          <AppWithoutSuspense/>
        </I18nextProvider>
      </React.Suspense>
    )
  } else {
    return <AppWithoutSuspense/>
  }
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};