import axios from 'axios';
import { API_URL } from '../config/environment';
import { returnErrors } from './errorActions';
import { keep2Decimals } from '../utils/pricing';
import * as reservationConstants from "../constants/reservation";

const noop = () => {};
/**
 * Define Actions
 */


export const GET_DRIVER_RESERVATIONS = 'GET_DRIVER_RESERVATIONS';
export const GET_STATION_OWNER_RESERVATIONS = 'GET_STATION_OWNER_RESERVATIONS';
export const ADD_OR_EDIT_NEW_RESERVATION = 'ADD_OR_EDIT_NEW_RESERVATION';
export const GET_RESERVATION_COST = 'GET_RESERVATION_COST';
export const COMMIT_NEW_RESERVATION = 'COMMIT_NEW_RESERVATION';
export const CLEAR_NEW_RESERVATION = 'CLEAR_NEW_RESERVATION';
export const CLEAR_NEW_RESERVATION_DATETIMES = 'CLEAR_NEW_RESERVATION_DATETIMES';
export const GET_PLUG_RESERVATIONS = 'GET_PLUG_RESERVATIONS';
export const CHECK_RESERVATION = 'CHECK_RESERVATION';
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const GET_USER_RESERVATIONS = 'GET_USER_RESERVATIONS';
/**
 * Define Action Creators
 */

/**
 * Return simple user's (driver) reservations
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getDriverReservations = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/my-reservations/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_DRIVER_RESERVATIONS,
      payload: res.data.results
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Return all reservations made to all of the stations owned by this owner
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getStationOwnerReservations = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/owner_station-reservations/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_STATION_OWNER_RESERVATIONS,
      payload: res.data
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {int} pageNum 
 * @param {int} pageSize 
 * @param {*} success_callback 
 * @param {*} error_callback 
 * @returns 
 */

export const getPaginatedStationOwnerReservations = (pageNum, pageSize, sort_field, search_field, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token;
  sort_field = sort_field || "-start_time";
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/owner_station-reservations/?page=${pageNum}&page_size=${pageSize}&ordering=${sort_field}&search=${search_field}`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_STATION_OWNER_RESERVATIONS,
      payload: res.data
    });
    success_callback();
  }).catch(err => {
    error_callback();
  });
};
/**
 * 
 * @param {*} plug_id 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getPlugReservations = (plug_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/plug-reservations/${plug_id}/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_PLUG_RESERVATIONS,
      payload: res.data.results
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} station_id 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getStationReservations = (station_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/station-reservations/${station_id}/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_PLUG_RESERVATIONS,
      payload: res.data.results
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Update reservation details in Redux state or add a new one
 * @param {Object} param0 
 */

export const addOrUpdateNewReservation = ({
  plug,
  reservation,
  paymentMethod,
  vehicle,
  charge_now,
  comments
}) => (dispatch, getState) => {
  const user = getState().user.user;
  dispatch({
    type: ADD_OR_EDIT_NEW_RESERVATION,
    payload: {
      user: {
        id: user ? user.id : null,
        email: user ? user.email : null
      },
      plug,
      reservation,
      paymentMethod,
      vehicle,
      charge_now,
      comments
    }
  }); // If after the above dispatch, plug and reservation datetimes are defined then calculate the total reservation_cost

  const newReservationState = getState().reservations.new_reservation;

  if (newReservationState.plug && newReservationState.reservation) {
    dispatch(getReservationCost());
  }
};
/**
 * 
 * @param {*} getState 
 */

const prepareReservationPostBody = getState => {
  const {
    user,
    plug,
    reservation,
    vehicle,
    paymentMethod,
    charge_now,
    comments
  } = getState().reservations.new_reservation;
  let body_data = {
    user: {
      email: user.email,
      id: user.id
    },
    plug: {
      id: plug.id
    },
    start_time: reservation.start_time,
    stop_time: reservation.stop_time,
    status: reservationConstants.PENDING,
    paid: false,
    comments: comments,
    payment_method: paymentMethod,
    charge_now: charge_now
  }; // Vehicle selection is optional, so we must check if it is null
  // before we add it to the POST request

  if (vehicle) {
    body_data.vehicle = {
      "id": vehicle.id
    };
  }

  return body_data;
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */


export const getReservationCost = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  let body_data = prepareReservationPostBody(getState); // Get token from localStorage

  const token = getState().user.token;
  var config = {
    method: 'post',
    url: `${API_URL}/api/stations/reservation-cost/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body_data
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_RESERVATION_COST,
      payload: {
        total_cost: keep2Decimals(res.data.total_cost),
        consumption_kWh: keep2Decimals(res.data.consumption_kWh),
        power_kW: keep2Decimals(res.data.power_kW)
      }
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));

      if (err.response.data.msg == "Start reservation time must be before Stop reservation time.") {
        dispatch({
          type: GET_RESERVATION_COST,
          payload: {
            total_cost: keep2Decimals(0)
          }
        });
      }
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const checkReservation = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  let body_data = prepareReservationPostBody(getState); // Get token from localStorage

  const token = getState().user.token;
  var config = {
    method: 'post',
    url: `${API_URL}/api/stations/check-reservation/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body_data
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  dispatch({
    type: CHECK_RESERVATION
  });
  return axios(config).then(res => {
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const postReservation = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  let body_data = prepareReservationPostBody(getState); // Get token from localStorage

  const token = getState().user.token;
  var config = {
    method: 'post',
    url: `${API_URL}/api/stations/reservations/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body_data
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: COMMIT_NEW_RESERVATION
    });
    success_callback(res.data);
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
export const clearNewReservation = () => dispatch => {
  dispatch({
    type: CLEAR_NEW_RESERVATION
  }); // dispatch(clearErrors())
};
export const clearNewReservationDatetimes = () => dispatch => {
  dispatch({
    type: CLEAR_NEW_RESERVATION_DATETIMES
  }); // dispatch(clearErrors())
};
/**
 * Cancel reservation by ID
 * @param {String} reservation_id UUID of reservation to be cancelled 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const cancelReservation = (reservation_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  var config = {
    method: 'post',
    url: `${API_URL}/api/stations/reservations/${reservation_id}/cancel`,
    headers: {
      'Content-Type': 'application/json'
    }
  }; // Get token from localStorage

  const token = getState().user.token;

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  axios(config).then(function (response) {
    dispatch({
      type: CANCEL_RESERVATION
    });
    success_callback();
  }).catch(function (error) {
    if (error.response) {
      dispatch(returnErrors(error.response.data, error.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Create a reservation that sets a Plug as unavailable.
 * This action can be called only by station owners!
 * 
 * @param {String} plug_id UUID of Plug to be set as Unavailable 
 * @param {Date} start_time datetime of unavailability start
 * @param {Date} stop_time datetime of unavailability stop
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const createPlugUnavailabilityReservation = (plug_id, start_time, stop_time, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  var config = {
    method: 'post',
    url: `${API_URL}/api/stations/plugs/${plug_id}/set-unavailable`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      start_time,
      stop_time
    }
  }; // Get token from localStorage

  const token = getState().user.token;

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  axios(config).then(function (response) {
    dispatch({
      type: COMMIT_NEW_RESERVATION
    });
    success_callback();
  }).catch(function (error) {
    error_callback();
  });
};
/**
 * 
 * @param {String} plug_id 
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const getUserReservations = (plug_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/my-plug-reservations/${plug_id}/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_USER_RESERVATIONS,
      payload: res.data.results
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 * @returns 
 */

export const getNextUpcomingReservation = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/reservations/next-reservation`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    const reservation = res.data;
    success_callback(reservation);
  }).catch(err => {
    error_callback();
  });
};
export const getPlugUpcomingReservation = (plug_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/reservations/${plug_id}/next-plug-reservation`,
    headers: {
      "Content-type": "application/json"
    },
    data: plug_id
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    const reservation = res.data;
    success_callback(reservation);
  }).catch(err => {
    error_callback();
  });
};
export const getStationReservationAvailability = (station_id, week_day, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token;
  var data = {
    "station_id": station_id,
    "weed_day": week_day + 1
  };
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/station-reservations/${station_id}/check-availability/?day=${week_day + 1}`,
    headers: {
      "Content-type": "application/json"
    },
    data: week_day + 1
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    success_callback(res.data.reservations_in_conflict);
  }).catch(err => {
    error_callback();
  });
};
export const getReservationById = (reservation_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/reservations/${reservation_id}/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    success_callback(res.data);
  }).catch(err => {
    error_callback(err);
  });
};