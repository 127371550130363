import _ from 'lodash';
/**
 * Merge two arrays of objects using lodash
 * @param {Array} current Current array of objects 
 * @param {Array} incoming Incoming array of objects to be merged to the current array
 * @param {String} key key based on which the merge will be done, defaults to "id" 
 * @returns {Array} new array with merged data
 */

export function mergeObjArraysByKey(current, incoming, key = "id") {
  const new_array = _(current) // start sequence
  .keyBy(key) // create a dictionary of the 1st array
  .merge(_.keyBy(incoming, key)) // create a dictionary of the 2nd array, and merge it to the 1st
  .values() // turn the combined dictionary to array
  .value(); // get the value (array) out of the sequence


  return new_array;
}