import { GET_RES_FORECAST_FROM_API, GET_RES_FORECAST_FROM_LOCAL_CACHE, GET_DAY_AHEAD_ENERGY_PRICES_FROM_API, GET_DAY_AHEAD_ENERGY_PRICES_FROM_LOCAL_CACHE, GET_FORECAST_RECOMMENDATION_FROM_API, GET_FORECAST_RECOMMENDATION_FROM_LOCAL_CACHE, TOGGLE_IN_SURGE_PRICING_STATE } from "../actions/energyActions";
import moment from "moment";
export const initialState = {
  xAxisPoints: [],
  resForecast: {
    quantity: [],
    lastUpdatedAt: null
  },
  dayAheadPrices: {
    priceAmount: [],
    lastUpdatedAt: null
  },
  forecastRecommendation: {
    recommendation: {},
    inSurgePricing: null,
    lastUpdatedAt: null
  }
};

const config = (state = initialState, action) => {
  switch (action.type) {
    case GET_RES_FORECAST_FROM_API:
      return { ...state,
        resForecast: {
          quantity: action.payload["quantity"],
          lastUpdatedAt: moment().format('D')
        },
        xAxisPoints: action.payload["position"]
      };

    case GET_DAY_AHEAD_ENERGY_PRICES_FROM_API:
      return { ...state,
        dayAheadPrices: {
          priceAmount: action.payload["price.amount"],
          lastUpdatedAt: moment().format('D')
        },
        xAxisPoints: action.payload["position"]
      };

    case GET_FORECAST_RECOMMENDATION_FROM_API:
      return { ...state,
        forecastRecommendation: { ...state.forecastRecommendation,
          recommendation: action.payload,
          lastUpdatedAt: moment().format('D')
        }
      };

    case TOGGLE_IN_SURGE_PRICING_STATE:
      return { ...state,
        forecastRecommendation: { ...state.forecastRecommendation,
          inSurgePricing: action.payload
        }
      };

    case GET_RES_FORECAST_FROM_LOCAL_CACHE:
    case GET_DAY_AHEAD_ENERGY_PRICES_FROM_LOCAL_CACHE:
    case GET_FORECAST_RECOMMENDATION_FROM_LOCAL_CACHE:
    default:
      return state;
  }
};

export default config;