import { USER_LOADING, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, REFRESH_USER_TOKEN, LOGIN_FAIL, LOGOUT_SUCCESS, REGISTER_SUCCESS, REGISTER_FAIL, UPDATE_USER_DETAILS, GOOGLE_AUTH_SUCCESS, APPLE_AUTH_SUCCESS, APPLE_AUTH_FAIL, GOOGLE_AUTH_FAIL } from "../actions/userActions"; // https://reactnative.dev/docs/platform-specific-code#platform-specific-extensions

import AsyncStorage from '../config/storage';
import { getFromStorage, saveToStorage } from '../config/storage';
var savedToken = getFromStorage('token');
export const initialState = {
  token: savedToken,
  isAuthenticated: false,
  isLoading: false,
  user: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return { ...state,
        isLoading: true
      };

    case USER_LOADED:
      return { ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };

    case REGISTER_SUCCESS:
      return state;

    case GOOGLE_AUTH_SUCCESS:
    case APPLE_AUTH_SUCCESS:
    case LOGIN_SUCCESS:
      saveToStorage("token", action.payload);
      return { ...state,
        token: action.payload,
        isAuthenticated: true,
        isLoading: false
      };

    case REFRESH_USER_TOKEN:
      return { ...state,
        token: { ...state.token,
          access: action.payload.access
        }
      };

    case AUTH_ERROR:
    case APPLE_AUTH_FAIL:
    case GOOGLE_AUTH_FAIL:
    case LOGIN_FAIL:
      return state;

    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      try {
        AsyncStorage.removeItem('token');
      } catch (error) {}

      return { ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };

    case UPDATE_USER_DETAILS:
      return { ...state,
        user: action.payload
      };

    default:
      return state;
  }
};

export default user;