import { GET_DRIVER_RESERVATIONS, GET_STATION_OWNER_RESERVATIONS, ADD_OR_EDIT_NEW_RESERVATION, GET_RESERVATION_COST, COMMIT_NEW_RESERVATION, CLEAR_NEW_RESERVATION, CLEAR_NEW_RESERVATION_DATETIMES, GET_PLUG_RESERVATIONS, CHECK_RESERVATION, CANCEL_RESERVATION, GET_USER_RESERVATIONS } from '../actions/reservationActions';
const initialNewReservation = {
  user: null,
  plug: null,
  reservation: null,
  paymentMethod: {
    type: "tokens",
    name: "Token Wallet"
  },
  total_cost: 0,
  vehicle: null,
  charge_now: null,
  consumption_kWh: 0,
  power_kW: 0,
  comments: ""
};
export const initialState = {
  driver_reservations: [],
  station_owner_reservations: [],
  station_owner_reservations_count: 0,
  plug_reservations: [],
  user_reservations: [],
  new_reservation: initialNewReservation
};

const reservations = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRIVER_RESERVATIONS:
      return { ...state,
        driver_reservations: action.payload
      };

    case GET_STATION_OWNER_RESERVATIONS:
      return { ...state,
        station_owner_reservations: action.payload.results,
        station_owner_reservations_count: action.payload.count
      };

    case GET_PLUG_RESERVATIONS:
      return { ...state,
        plug_reservations: action.payload
      };

    case GET_USER_RESERVATIONS:
      return { ...state,
        user_reservations: action.payload
      };

    case ADD_OR_EDIT_NEW_RESERVATION:
      const isChargeNowPayloadDefined = action.payload.charge_now !== null && action.payload.charge_now !== undefined;
      return { ...state,
        new_reservation: {
          user: action.payload.user || state.new_reservation.user,
          plug: action.payload.plug || state.new_reservation.plug,
          reservation: action.payload.reservation || state.new_reservation.reservation,
          paymentMethod: action.payload.paymentMethod || state.new_reservation.paymentMethod,
          total_cost: action.payload.total_cost || state.new_reservation.total_cost,
          vehicle: action.payload.vehicle || state.new_reservation.vehicle,
          charge_now: isChargeNowPayloadDefined ? action.payload.charge_now : state.new_reservation.charge_now,
          consumption_kWh: action.payload.consumption_kWh || state.new_reservation.consumption_kWh,
          power_kW: action.payload.power_kW || state.new_reservation.power_kW,
          comments: action.payload.comments || state.new_reservation.comments
        }
      };

    case GET_RESERVATION_COST:
      const isTotalCostPayloadDefined = action.payload.total_cost !== null && action.payload.total_cost !== undefined;
      return { ...state,
        new_reservation: { ...state.new_reservation,
          total_cost: isTotalCostPayloadDefined ? action.payload.total_cost : state.new_reservation.total_cost,
          consumption_kWh: action.payload.consumption_kWh || state.new_reservation.consumption_kWh,
          power_kW: action.payload.power_kW || state.new_reservation.power_kW
        }
      };

    case CLEAR_NEW_RESERVATION_DATETIMES:
      return { ...state,
        new_reservation: { ...state.new_reservation,
          reservation: null
        }
      };

    case CLEAR_NEW_RESERVATION:
    case COMMIT_NEW_RESERVATION:
      return { ...state,
        new_reservation: initialNewReservation
      };

    case CHECK_RESERVATION:
    case CANCEL_RESERVATION:
    default:
      return state;
  }
};

export default reservations;