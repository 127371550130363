import { STORAGE_BACKEND } from "../config/storage";
import { UPDATE_PUSH_TOKEN, PUSH_TOKEN_BACKEND_ERROR, UPDATE_PREFERRED_DEVICE_LANGUAGE } from "../actions/configActions"; // Geolocation permission states

export const LOCATION_GRANTED_NO_DIALOG_10 = 'LOCATION_GRANTED_NO_DIALOG_10';
export const LOCATION_DENIED_SHOW_DIALOG_01 = 'LOCATION_DENIED_SHOW_DIALOG_01';
export const LOCATION_DENIED_NO_DIALOG_00 = 'LOCATION_DENIED_NO_DIALOG_00';
const emptyPushTokenConfig = {
  configured: false,
  platform: null,
  token: null
};
export const initialState = {
  storageBackend: STORAGE_BACKEND,
  pushToken: emptyPushTokenConfig,
  preferredDeviceLang: null,
  geolocationPermission: LOCATION_DENIED_SHOW_DIALOG_01
};

const config = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PUSH_TOKEN:
      return { ...state,
        pushToken: {
          configured: true,
          platform: action.payload.platform,
          token: action.payload.token
        }
      };

    case PUSH_TOKEN_BACKEND_ERROR:
      return { ...state,
        pushToken: { ...state.pushToken,
          configured: false
        }
      };

    case UPDATE_PREFERRED_DEVICE_LANGUAGE:
      return { ...state,
        preferredDeviceLang: action.payload.lang
      };

    case LOCATION_GRANTED_NO_DIALOG_10:
    case LOCATION_DENIED_SHOW_DIALOG_01:
    case LOCATION_DENIED_NO_DIALOG_00:
      return { ...state,
        geolocationPermission: action.type
      };

    default:
      return state;
  }
};

export default config;