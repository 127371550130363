import axios from 'axios';
import { API_URL } from '../config/environment';
import { returnErrors } from './errorActions';

const noop = () => {};
/**
 * Define Actions
 */


export const START_OCPP_TRANSACTION = 'START_OCPP_TRANSACTION';
export const STOP_OCPP_TRANSACTION = 'STOP_OCPP_TRANSACTION';
export const GET_PLUG_OCPP_STATUS = 'GET_PLUG_OCPP_STATUS';
export const GET_METER_VALUES = 'GET_METER_VALUES';
export const GET_TOTAL_KWH = 'GET_TOTAL_KWH';
/**
 * Define Action Creators
 */

/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const startOcppTransaction = (reservation_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // TODO: Add user authentication/ permissions
  const user = getState().user;
  var config = {
    method: 'post',
    url: `${API_URL}/api/ocpp/charging-point/${reservation_id}/start-transaction`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: reservation_id
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const stopOcppTransaction = (reservation_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // TODO: Add user authentication/ permissions
  const user = getState().user;
  var config = {
    method: 'post',
    url: `${API_URL}/api/ocpp/charging-point/${reservation_id}/stop-transaction`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: reservation_id
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getPlugsOcppStatus = (station_slug, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Step 1: Get the plugs of a particular station
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/get-station-by-slug/${station_slug}/`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    const station = res.data;
    let initialState = []; // Loop throught he plugs of the station to get 
    // The initial State

    station.plugs.forEach((plug, index) => {
      initialState[index] = {
        plug
      };
    }); // Instanciate a websocket connection for each plug

    station.plugs.forEach((plug, index) => {
      if (window.wsConnections === undefined) {
        window.wsConnections = {};
      }

      window.wsConnections[plug.id] = new WebSocket(API_URL.replace("http", "ws") + "/ocpp/browser/" + plug.id + "/"); // Update the state that stores the ocpp_status
      // upon receive a websocket message

      window.wsConnections[plug.id].onmessage = event => {
        let data = JSON.parse(event.data);
        let id = data.plug;
        let status = data.status;
        let error = data.error;
        let session = data.session;
        initialState[index] = {
          id: data.plug,
          status: data.status,
          error: data.error,
          // Persist websocket in hook's state
          // Remove this if it is not used
          //ws: ws
          meter_value: [],
          session: data.session
        };
        dispatch({
          type: GET_PLUG_OCPP_STATUS,
          initialState,
          id,
          status,
          error,
          session
        });
      };
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
export const getMeterValues = (plug_id, user_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Step 1: Get the past total kWh before making the websocket connection
  const user = getState().user; // Step 2: Create a websocket connection request to the private URL

  let ws = new WebSocket(API_URL.replace("http", "ws") + "/ocpp/browser/" + plug_id + "/" + user_id + "/");

  ws.onmessage = event => {
    let data = JSON.parse(event.data);
    dispatch({
      type: GET_METER_VALUES,
      data,
      plug_id
    });
  };
};
export const getTotalKwh = (plug_id, reservation_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Step 1: Get the past total kWh before making the websocket connection
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/reservations/${reservation_id}/consumption`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    let total_kwh = res.data.total_kwh;
    dispatch({
      type: GET_TOTAL_KWH,
      total_kwh,
      plug_id
    });
  });
};
/**
 * Returns the list of all user's idTags
 * @param {Function} success_callback function to execute in successful result after getting the idTags,
 *    must accept as argument the result, list of idTags
 * @param {Function} error_callback 
 * @returns 
 */

export const getIdTags = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/ocpp/id-tags/`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback(res.data);
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Create a new idTag that will be associated to the Plug with the given plug_id
 * @param {String} plug_id 
 * @param {String} id_tag 
 * @param {Function} success_callback function to execute in successful result after posting the idTag,
 *    must accept as argument the result
 * @param {Function} error_callback 
 * @returns 
 */

export const postIdTag = (charging_point, id_tag, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  const bodyData = {
    user: {
      email: user.user.email
    },
    idTag: id_tag,
    charging_point: charging_point
  };
  var config = {
    method: 'post',
    url: `${API_URL}/api/ocpp/id-tags/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: bodyData
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback(res.data);
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Update an existing idTag
 * @param {String} obj_id The ID of the existing idTag object
 * @param {String} plug_id 
 * @param {String} new_id_tag 
 * @param {Function} success_callback function to execute in successful result after posting the idTag,
 *    must accept as argument the result
 * @param {Function} error_callback 
 * @returns 
 */

export const updateIdTag = (obj_id, charging_point, new_id_tag, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  const bodyData = {
    id: obj_id,
    user: {
      email: user.user.email
    },
    idTag: new_id_tag,
    charging_point: charging_point
  };
  var config = {
    method: 'put',
    url: `${API_URL}/api/ocpp/id-tags/${obj_id}/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: bodyData
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback(res.data);
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Delete an idTag
 * @param {String} id_tag_id The idTag's object ID used to delete the whole Object
 * @param {Function} success_callback function to execute in successful result after posting the idTag,
 *    must accept as argument the result
 * @param {Function} error_callback 
 * @returns 
 */

export const deleteIdTag = (id_tag_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'delete',
    url: `${API_URL}/api/ocpp/id-tags/${id_tag_id}/`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback(res.data);
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
export const cleanOcppStatusWebsockets = () => dispatch => {
  // Get plug IDs with active websocket connections
  if (window.wsConnections) {
    const plugIds = Object.keys(window.wsConnections);
    plugIds.forEach(plugId => {
      window.wsConnections[plugId].close();
    });
  }

  dispatch({
    type: GET_PLUG_OCPP_STATUS,
    initialState: []
  });
};
/**
 * Call API endpoint responsible to send to the charger a reset OCPP operation
 * @param {String} plug_id ID of Plug to be reset
 * @param {String} type Reset type: "Soft", or "Hard"
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 * @returns 
 */

export const sendResetOperation = (plug_id, type, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  const bodyData = {
    type: type
  };
  var config = {
    method: 'post',
    url: `${API_URL}/api/ocpp/charging-point/${plug_id}/reset-plug`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: bodyData
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback(res.data);
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};