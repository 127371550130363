/**
 * Define Actions
 */
export const UPDATE_PUSH_TOKEN = 'UPDATE_PUSH_TOKEN';
export const PUSH_TOKEN_BACKEND_ERROR = 'PUSH_TOKEN_BACKEND_ERROR';
export const UPDATE_PREFERRED_DEVICE_LANGUAGE = 'UPDATE_PREFERRED_DEVICE_LANGUAGE';
/**
 * Define Action Creators
 */
//  Return & Handle errors

export const updatePushToken = (token, platform) => dispatch => {
  dispatch({
    type: UPDATE_PUSH_TOKEN,
    payload: {
      token,
      platform
    }
  });
};
export const pushTokenBackendError = () => {
  return {
    type: PUSH_TOKEN_BACKEND_ERROR
  };
};
export const updatePreferredLang = lang => dispatch => {
  dispatch({
    type: UPDATE_PREFERRED_DEVICE_LANGUAGE,
    payload: {
      lang
    }
  });
};