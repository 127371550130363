import axios from 'axios';
import { API_URL, APP_ENV, ENVIRONMENT } from "../config/environment";
import { returnErrors, clearErrors, GET_ERRORS } from './errorActions';
import qs from 'qs';

const noop = () => {};
/**
 * Define Actions
 */


export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REFRESH_USER_TOKEN = "REFRESH_USER_TOKEN";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const RESEND_ACTIVATION_EMAIL_SUCCESS = "RESEND_ACTIVATION_EMAIL_SUCCESS";
export const RESEND_ACTIVATION_EMAIL_FAIL = "RESEND_ACTIVATION_EMAIL_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const APPLE_AUTH_SUCCESS = "APPLE_AUTH_SUCCESS";
export const APPLE_AUTH_FAIL = "APPLE_AUTH_FAIL";
/**
 * Define Action Creators
 */

/**
 * Register User
 * @param {Object} param0 user details
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const registerUser = ({
  first_name,
  last_name,
  email,
  phone_number,
  password,
  role,
  lang = "en"
}, success_callback = noop, error_callback = noop) => dispatch => {
  return axios.post(`${API_URL}/api/users/`, {
    first_name,
    last_name,
    email,
    phone_number,
    password,
    username: email,
    role: {
      name: role
    },
    settings: {
      lang
    }
  }).then(res => {
    dispatch({
      type: REGISTER_SUCCESS
    });
    dispatch(clearErrors());
    success_callback();
  }).catch(err => {
    dispatch({
      type: REGISTER_FAIL
    });

    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Resend Activation Email
 * @param {Object} email  user email
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const resendActivationEmail = (email, success_callback = noop, error_callback = noop) => dispatch => {
  return axios.post(`${API_URL}/api/users/resend_activation/`, {
    email
  }).then(res => {
    dispatch({
      type: RESEND_ACTIVATION_EMAIL_SUCCESS
    });
    dispatch(clearErrors());
    success_callback();
  }).catch(err => {
    dispatch({
      type: RESEND_ACTIVATION_EMAIL_FAIL
    });

    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} email 
 * @param {*} password 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const loginUser = (email, password, success_callback = noop, error_callback = noop) => dispatch => {
  // 
  return axios.post(`${API_URL}/api/auth/jwt/create`, {
    email,
    password
  }).then(res => {
    if (res.data.access) {
      // 
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      dispatch(loadUser(success_callback, error_callback));
    } else {
      dispatch({
        type: LOGIN_FAIL
      });
    }
  }).catch(err => {
    dispatch({
      type: LOGIN_FAIL
    });

    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const refreshUserToken = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get refresh token from state/localStorage
  const token = getState().user.token;
  var config = {
    method: 'post',
    url: `${API_URL}/api/auth/jwt/refresh`,
    headers: {
      "Content-type": "application/json"
    },
    data: {
      refresh: token?.refresh
    }
  };
  return axios(config).then(res => {
    dispatch({
      type: REFRESH_USER_TOKEN,
      payload: res.data
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    dispatch({
      type: AUTH_ERROR
    });
    dispatch(logoutUser());
    error_callback();
  });
};
/**
 * Check token & load user
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const loadUser = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // User loading
  dispatch({
    type: USER_LOADING
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/users/me/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    dispatch({
      type: AUTH_ERROR
    });
    error_callback();
  });
};
export const logoutUser = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: LOGOUT_SUCCESS
    });
    resolve();
  });
};
/**
 * 
 * @param {*} newUserDetails 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const updateUserDetails = (newUserDetails, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const user = getState().user;
  var config = {
    method: 'put',
    url: `${API_URL}/api/users/me/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { ...user.user,
      ...newUserDetails
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: UPDATE_USER_DETAILS,
      payload: res.data
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
export const deleteUserAccount = (user_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'post',
    url: `${API_URL}/api/users/${user_id}/account-delete/`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback();
  }).catch(err => {
    error_callback();
  });
};
/**
 * 
 * @param {*} current_password 
 * @param {*} new_password 
 * @param {*} new_password_confirmation 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const changeUserPassword = (current_password, new_password, new_password_confirmation, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  if (new_password === new_password_confirmation) {
    // Change password only if user hasn't made a mistake in typing it twice (password confirmation matches the first one)
    const user = getState().user;
    var config = {
      method: 'post',
      url: `${API_URL}/api/users/set_password/`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        current_password: current_password,
        new_password: new_password
      }
    };

    if (user.token && user.token.access) {
      config.headers['Authorization'] = "Bearer " + user.token.access;
    }

    return axios(config).then(res => {
      dispatch({
        type: CHANGE_USER_PASSWORD
      });
      success_callback();
    }).catch(err => {
      if (err.response) {
        dispatch(returnErrors(err.response.data.msg, err.response.status));
      } else {
        dispatch(returnErrors("No response from server", null));
      }

      error_callback();
    });
  } else {
    dispatch({
      type: GET_ERRORS,
      payload: {
        msg: "Passwords do not match",
        status: 417,
        id: 417
      }
    });
    error_callback();
  }
};
export const googleAuthenticate = (state, code) => async dispatch => {
  if (state && code) {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
    const details = {
      state: state,
      code: code
    };
    const formBody = Object.keys(details).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(details[key])).join("&"); // console.log("deatails", details);
    // console.log("body", formBody);

    try {
      var googleOauthURL = ''; // console.log(APP_ENV);

      if (APP_ENV === 'local') {
        if (ENVIRONMENT === 'MOBILE') {
          googleOauthURL = `http://evloader.com:8000/api/auth/o/google-oauth2/?${formBody}/`; // for mobile Use
        } else {
          googleOauthURL = `http://localhost:8000/api/auth/o/google-oauth2/?${formBody}/`; // for web use
        }
      } else {
        googleOauthURL = `${API_URL}/api/auth/o/google-oauth2/?${formBody}/`;
      }

      const res = await axios.post(googleOauthURL, config, {
        withCredentials: true
      });
      dispatch({
        type: GOOGLE_AUTH_SUCCESS,
        payload: res.data
      });
      dispatch(loadUser(() => {}, () => {}));
    } catch (err) {
      dispatch({
        type: GOOGLE_AUTH_FAIL
      });
    }
  }
};
export const appleAuthenticate = (state, code) => async dispatch => {
  if (state && code) {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
    const details = {
      state: state,
      code: code
    };
    const formBody = Object.keys(details).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(details[key])).join("&"); // console.log("deatails", details);
    // console.log("body", formBody);

    try {
      var appleOauthURL = '';
      appleOauthURL = `${API_URL}/api/auth/o/apple-id/?${formBody}`;
      const res = await axios.post(appleOauthURL, config, {
        withCredentials: true
      });
      dispatch({
        type: APPLE_AUTH_SUCCESS,
        payload: res.data
      });
      dispatch(loadUser(() => {}, () => {}));
    } catch (err) {
      dispatch({
        type: APPLE_AUTH_FAIL
      });
    }
  }
};