import axios from 'axios';
import { API_URL } from '../config/environment';
import { returnErrors } from './errorActions';

const noop = () => {};
/**
 * Define Actions
 */


export const CREATE_USER_VEHICLE = 'CREATE_USER_VEHICLE';
export const UPDATE_USER_VEHICLE = 'UPDATE_USER_VEHICLE';
export const FETCH_USER_VEHICLES = 'FETCH_USER_VEHICLES';
export const SAVE_USER_VEHICLES = 'SAVE_USER_VEHICLES';
export const FETCH_VEHICLE_MODELS = 'FETCH_VEHICLE_MODELS';
export const SAVE_VEHICLE_MODELS = 'SAVE_VEHICLE_MODELS';
export const UPLOAD_VEHICLE_IMAGE = 'UPLOAD_VEHICLE_IMAGE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';
/**
 * Define Action Creators
 */

/**
  * 
  * @param {*} success_callback 
  * @param {*} error_callback 
  */

export const createUserVehicle = (model, color, plate_number, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'post',
    url: `${API_URL}/api/my-vehicles/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      "model": model,
      "driver": getState().user.user,
      "color": color,
      "plate_number": plate_number
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  axios(config).then(function (response) {
    dispatch({
      type: CREATE_USER_VEHICLE
    });
    success_callback(response.data.id);
  }).catch(function (error) {
    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const updateUserVehicle = (vehicle, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'put',
    url: `${API_URL}/api/my-vehicles/${vehicle.id}/`,
    headers: {
      "Content-type": "application/json"
    },
    data: vehicle
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: UPDATE_USER_VEHICLE
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchUserVehicles = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  dispatch({
    type: FETCH_USER_VEHICLES
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/my-vehicles/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveUserVehicles(res.data));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} vehicles
 */

export const saveUserVehicles = vehicles => {
  return {
    type: SAVE_USER_VEHICLES,
    vehicles
  };
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchVehicleModels = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  dispatch({
    type: FETCH_VEHICLE_MODELS
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/users/vehicles/models-by-manufacturer/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveVehicleModels(res.data));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} models
 */

export const saveVehicleModels = models => {
  return {
    type: SAVE_VEHICLE_MODELS,
    models
  };
};
/**
 * 
 * @param {String} vehicle_id 
 * @param {File} image 
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const uploadVehicleImage = (vehicle_id, image, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var data = new FormData();
  data.append('file', image);
  var config = {
    method: 'post',
    url: `${API_URL}/api/vehicles/${vehicle_id}/upload-image/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} vehicle_id 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const deleteVehicle = (vehicle_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'delete',
    url: `${API_URL}/api/my-vehicles/${vehicle_id}/`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: DELETE_VEHICLE,
      vehicle_id_to_delete: vehicle_id
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};