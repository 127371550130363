import React, { useState, useEffect } from "react";
import useOperatingSystemDetection from "hooks/useOperatingSystemDetection";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { useRouter } from "next/router";
import { APP_ENV, API_URL_HOST } from "@js-shared/lib/config/environment";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useCookies } from "react-cookie";

const DEEP_LINK_BASE_URI = "evloader://";
const PLAY_STORE_APP_LINK = "https://play.google.com/store/apps/details?id=com.parityplatform.evloader";
const APP_STORE_APP_LINK = "https://apple.co/3d2SFgp";

// Do not show the smart banner in these links
const PATH_EXCEPTIONS = [
  "/termsofuse",
  "/privacy",
  "/contact",
  "/about",
  "/complete-payment-session"
]

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    background: "#ebebeb",
    zIndex: 2000,
    height: "auto",
  },
  element: {
    paddingRight: 20,
  },
}));

export default function SmartBanner() {
  const router = useRouter();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["hide_smart_banner"]);
  const { isMobile, isAndroid, isApple, isIos } = useOperatingSystemDetection();
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  useEffect(() => {
    let isAllowedPath = true;
    PATH_EXCEPTIONS.forEach(path => {
      if (router.asPath.includes(path)) isAllowedPath = false;
    })
    const cookieExists = cookies.hide_smart_banner !== undefined
    const cookieDoNotHideBanner = cookieExists ? cookies.hide_smart_banner !== "true" : true

    if (isMobile && isAllowedPath && cookieDoNotHideBanner) {
      setShouldShowBanner(true)
    } else {
      setShouldShowBanner(false)
    }
    // console.log(cookies.hide_smart_banner)
  }, [isMobile, router.asPath])

  const openInApp = () => {
    var now = new Date().valueOf();

    setTimeout(function () {
      if (new Date().valueOf() - now > 100) return;

      if (isAndroid) {
        window.location = PLAY_STORE_APP_LINK;
      }
      if (isIos || isApple) {
        window.location = APP_STORE_APP_LINK;
      }
    }, 25);

    if (APP_ENV === "production" || APP_ENV === "staging") {
      window.location = DEEP_LINK_BASE_URI + router.asPath;
    } else {
      window.location = `exp://${API_URL_HOST}:19000/--${router.asPath}`;
    }
  };

  const handleHideSmartBanner = () => {
    setCookie("hide_smart_banner", true, { path: "/" }); // Caution: the boolean value true will be stored as string
    setShouldShowBanner(false)
  }

  if (shouldShowBanner) {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        className={classes.bannerContainer}
      >
        <Box flexGrow={1}>
          <IconButton 
            aria-label="close-in-app" 
            onClick={handleHideSmartBanner}
          >
            <CloseIcon/>
          </IconButton>
        </Box>
        <Box p={1}>
          <Button
            className={classes.button}
            disableElevation
            color="primary"
            variant="outlined"
            onClick={openInApp}
            naked
          >
            Open in app
          </Button>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
