import { FETCH_STATIONS, FETCH_STATIONS_NEARBY, SAVE_STATIONS, SAVE_STATIONS_NEARBY, FETCH_RECENT_STATIONS, SAVE_RECENT_STATIONS, FETCH_STATION_OWNER_STATIONS, SAVE_STATION_OWNER_STATIONS, FETCH_STATION_OWNER_ANALYTICS, SAVE_STATION_OWNER_ANALYTICS, FETCH_FEATURED_STATIONS, SAVE_FEATURED_STATIONS, UPDATE_STATION, DELETE_STATION, UPDATE_PLUG, DELETE_PLUG, FETCH_STATION_TRANSACTIONS, SAVE_STATION_TRANSACTIONS, FETCH_STATION_CHARGING_POINTS, SAVE_STATION_CHARGING_POINTS } from '../actions/stationActions';
import { mergeObjArraysByKey } from '../utils/data';
export const initialState = {
  fetched_stations: [],
  fetched_station_transactions: [],
  fetched_station_charging_points: [],
  station_owner_stations: [],
  station_owner_analytics: [],
  latest_stations: [],
  featured_stations: []
};

const stations = (state = initialState, action) => {
  switch (action.type) {
    // Merge stations with those already stored locally
    case SAVE_STATIONS:
      return { ...state,
        fetched_stations: mergeObjArraysByKey(state.fetched_stations, action.stations.results)
      };
    // Reset fetched_stations list by storing the new stations

    case SAVE_STATIONS_NEARBY:
      return { ...state,
        fetched_stations: action.stations.results
      };

    case SAVE_STATION_TRANSACTIONS:
      return { ...state,
        fetched_station_transactions: action.transactions
      };

    case SAVE_STATION_CHARGING_POINTS:
      return { ...state,
        fetched_station_charging_points: action.charging_points
      };

    case SAVE_RECENT_STATIONS:
      return { ...state,
        latest_stations: action.stations.latest_stations
      };

    case SAVE_STATION_OWNER_STATIONS:
      return { ...state,
        station_owner_stations: action.stations.results
      };

    case SAVE_STATION_OWNER_ANALYTICS:
      return { ...state,
        station_owner_analytics: action.stations
      };

    case SAVE_FEATURED_STATIONS:
      return { ...state,
        featured_stations: action.stations.featured_stations
      };

    case FETCH_STATIONS:
    case FETCH_STATIONS_NEARBY:
    case FETCH_STATION_TRANSACTIONS:
    case FETCH_STATION_CHARGING_POINTS:
    case FETCH_RECENT_STATIONS:
    case FETCH_FEATURED_STATIONS:
    case FETCH_STATION_OWNER_STATIONS:
      return state;

    case FETCH_STATION_OWNER_ANALYTICS:
      return state;

    case UPDATE_STATION:
      return { ...state,
        station_owner_stations: state.station_owner_stations.map(station => {
          if (station.id === action.station.id) return action.station;else return station;
        })
      };

    case DELETE_STATION:
      return { ...state,
        station_owner_stations: state.station_owner_stations.filter(station => {
          return station.id !== action.station_id_to_delete;
        })
      };

    case UPDATE_PLUG:
      let plug_id = action.plug.id;
      let station_id = action.plug.station.id;
      return { ...state,
        station_owner_stations: state.station_owner_stations.map(station => {
          if (station.id === station_id) {
            return { ...station,
              plugs: station.plugs.map(plug => {
                if (plug.id === plug_id) {
                  return action.plug;
                } else return plug;
              })
            };
          } else return station;
        })
      };

    case DELETE_PLUG:
      plug_id = action.plug.id;
      station_id = action.plug.station.id;
      return { ...state,
        station_owner_stations: state.station_owner_stations.map(station => {
          if (station.id === station_id) {
            return { ...station,
              plugs: station.plugs.filter(plug => {
                return plug.id !== plug_id;
              })
            };
          } else return station;
        })
      };

    default:
      return state;
  }
};

export default stations;