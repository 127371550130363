import axios from 'axios';
import moment from "moment";
/**
 * Define Actions
 */

export const GET_RES_FORECAST_FROM_API = 'GET_RES_FORECAST_FROM_API';
export const GET_RES_FORECAST_FROM_LOCAL_CACHE = 'GET_RES_FORECAST_FROM_LOCAL_CACHE';
export const GET_DAY_AHEAD_ENERGY_PRICES_FROM_API = 'GET_DAY_AHEAD_ENERGY_PRICES_FROM_API';
export const GET_DAY_AHEAD_ENERGY_PRICES_FROM_LOCAL_CACHE = 'GET_DAY_AHEAD_ENERGY_PRICES_FROM_LOCAL_CACHE';
export const GET_FORECAST_RECOMMENDATION_FROM_API = 'GET_FORECAST_RECOMMENDATION_FROM_API';
export const GET_FORECAST_RECOMMENDATION_FROM_LOCAL_CACHE = 'GET_FORECAST_RECOMMENDATION_FROM_LOCAL_CACHE';
export const TOGGLE_IN_SURGE_PRICING_STATE = 'TOGGLE_IN_SURGE_PRICING_STATE';
/**
 * Define Action Creators
 */
//  Return & Handle errors

export const getResForecastFromCacheOrFetch = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const today = moment().format('D');
  const lastUpdatedDay = getState().energy.resForecast.lastUpdatedAt;

  if (today === lastUpdatedDay) {
    // get data from redux state
    dispatch({
      type: GET_RES_FORECAST_FROM_LOCAL_CACHE
    }); // Success callback with (x,y) data

    success_callback(getState().energy.xAxisPoints, getState().energy.resForecast["quantity"]);
  } else {
    // fresh fetch of data
    return axios.get("https://evloader.com/api/energy/v1/res-forecast").then(res => {
      dispatch({
        type: GET_RES_FORECAST_FROM_API,
        payload: res.data
      });
      success_callback(res.data["position"], res.data["quantity"]);
    }).catch(error => {
      error_callback();
    });
  }
};
export const getDayAheadPricestFromCacheOrFetch = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const today = moment().format('D');
  const lastUpdatedDay = getState().energy.dayAheadPrices.lastUpdatedAt;

  if (today === lastUpdatedDay) {
    // get data from redux state
    dispatch({
      type: GET_DAY_AHEAD_ENERGY_PRICES_FROM_LOCAL_CACHE
    }); // Success callback with (x,y) data

    success_callback(getState().energy.xAxisPoints, getState().energy.dayAheadPrices["priceAmount"]);
  } else {
    // fresh fetch of data
    return axios.get("https://evloader.com/api/energy/v1/day-ahead-prices").then(res => {
      dispatch({
        type: GET_DAY_AHEAD_ENERGY_PRICES_FROM_API,
        payload: res.data
      }); // Success callback with (x,y) data

      success_callback(res.data["position"], res.data["price.amount"]);
    }).catch(error => {
      error_callback();
    });
  }
};
export const getForecastRecommendationFromCacheOrFetch = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const today = moment().format('D');
  const lastUpdatedDay = getState().energy.dayAheadPrices.lastUpdatedAt;

  if (today === lastUpdatedDay) {
    // get data from redux state
    dispatch({
      type: GET_FORECAST_RECOMMENDATION_FROM_LOCAL_CACHE
    }); // Success callback with (x,y) data

    success_callback(getState().energy.forecastRecommendation.recommendation);
  } else {
    // fresh fetch of data
    return axios.get("https://evloader.com/api/energy/v1/day-ahead-price-vs-res-forecast-recommendation").then(res => {
      dispatch({
        type: GET_FORECAST_RECOMMENDATION_FROM_API,
        payload: res.data
      }); // Success callback with (x,y) data

      success_callback(res.data);
    }).catch(error => {
      error_callback();
    });
  }
};
export const toogleInSurgePricingState = newState => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_IN_SURGE_PRICING_STATE,
    payload: newState
  });
};