import { SUBMIT_SEARCH_QUERY, UPDATE_FOCUSED_LOCATION, UPDATE_ZOOM_ONLY, GET_SEARCH_RESULTS, CLEAR_SEARCH } from '../actions/searchActions';
export const initialState = {
  query: "",
  focused_location: {
    lat: null,
    lng: null,
    zoom: null
  },
  details: null
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_SEARCH_QUERY:
      return { ...state,
        query: action.payload.formatted_address,
        details: action.payload
      };

    case UPDATE_FOCUSED_LOCATION:
      return { ...state,
        focused_location: {
          lat: action.lat,
          lng: action.lng,
          zoom: action.zoom
        }
      };

    case UPDATE_ZOOM_ONLY:
      return { ...state,
        focused_location: { ...state.focused_location,
          zoom: action.zoom
        }
      };

    case GET_SEARCH_RESULTS:
      return state;

    case CLEAR_SEARCH:
      return initialState;

    default:
      return state;
  }
};

export default search;