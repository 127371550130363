import axios from 'axios';
import { returnErrors, handleError } from './errorActions';
import { API_URL } from '../config/environment';

const noop = () => {};
/**
 * Define Actions
 */


export const FETCH_STATIONS = 'FETCH_STATIONS';
export const FETCH_STATIONS_NEARBY = 'FETCH_STATIONS_NEARBY';
export const SAVE_STATIONS = 'SAVE_STATIONS';
export const SAVE_STATIONS_NEARBY = 'SAVE_STATIONS_NEARBY';
export const FETCH_STATION_OWNER_STATIONS = 'FETCH_STATION_OWNER_STATIONS';
export const FETCH_STATION_TRANSACTIONS = 'FETCH_STATION_TRANSACTIONS';
export const SAVE_STATION_OWNER_STATIONS = 'SAVE_STATION_OWNER_STATIONS';
export const SAVE_STATION_TRANSACTIONS = 'SAVE_STATION_TRANSACTIONS';
export const FETCH_STATION_OWNER_ANALYTICS = 'FETCH_STATION_OWNER_ANALYTICS';
export const SAVE_STATION_OWNER_ANALYTICS = 'SAVE_STATION_OWNER_ANALYTICS';
export const FETCH_RECENT_STATIONS = 'FETCH_RECENT_STATIONS';
export const SAVE_RECENT_STATIONS = 'SAVE_RECENT_STATIONS';
export const FETCH_FEATURED_STATIONS = 'FETCH_FEATURED_STATIONS';
export const SAVE_FEATURED_STATIONS = 'SAVE_FEATURED_STATIONS';
export const UPDATE_STATION = 'UPDATE_STATION';
export const DELETE_STATION = 'DELETE_STATION';
export const UPDATE_PLUG = 'UPDATE_PLUG';
export const DELETE_PLUG = 'DELETE_PLUG';
export const FETCH_STATION_CHARGING_POINTS = 'FETCH_STATION_CHARGING_POINTS';
export const SAVE_STATION_CHARGING_POINTS = 'SAVE_STATION_CHARGING_POINTS';
export const DESELECT_PREV_SELECTED_STATION = 'DESELECT_PREV_SELECTED_STATION';
/**
 * Define Action Creators
 */

/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchStations = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get User from Local Storage
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/`,
    headers: {
      "Content-type": "application/json"
    }
  }; // If User is Authenticated add token, so that private stations can be fetched as well

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  dispatch({
    type: FETCH_STATIONS
  });
  return axios(config).then(res => {
    dispatch(saveStations(res.data));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} stations 
 */

export const saveStations = stations => {
  return {
    type: SAVE_STATIONS,
    stations
  };
};
/**
 * 
 * @param {*} stations 
 */

export const saveStationsNearBy = stations => {
  return {
    type: SAVE_STATIONS_NEARBY,
    stations
  };
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchRecentStations = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get User from Local Storage
  const user = getState().user;
  dispatch({
    type: FETCH_RECENT_STATIONS
  });
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/recent-reservation-stations/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    dispatch(saveRecentStations(res.data));
    success_callback();
  }).catch(error => {
    if (error.response) {
      dispatch(returnErrors(error.response.data, error.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
export const saveRecentStations = stations => {
  return {
    type: SAVE_RECENT_STATIONS,
    stations
  };
};
/**
 * 
 * @param {*} lat Latitude
 * @param {*} lng Longitude
 * @param {*} zoom_level Desired zoom level, optional, defaults to 10
 * @param {Object} user user object from redux state 
 * @returns config object to use with axios
 */

export const prepareFetchingSearchedStations = (lat, lng, zoom_level = 10, user) => {
  // Make sure zoom_level is not passed in as null
  if (zoom_level === null) {
    zoom_level = 10;
  }

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/search/${lat}&${lng}&${zoom_level}/`,
    headers: {
      "Content-type": "application/json"
    }
  }; // If User is Authenticated add token, so that private stations can be fetched as well

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return config;
};
/**
 * 
 * @param {*} lat Latitude
 * @param {*} lng Longitude
 * @param {*} zoom_level Zoom-level, also known as lat-lon-delta
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchSearchedStations = (lat, lng, zoom_level = 10, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get User from Local Storage
  const user = getState().user;
  var config = prepareFetchingSearchedStations(lat, lng, zoom_level, user);
  dispatch({
    type: FETCH_STATIONS
  });
  return axios(config).then(res => {
    dispatch(saveStations(res.data));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} lat Latitude
 * @param {*} lng Longitude
 * @param {*} zoom_level Zoom-level, also known as lat-lon-delta
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchSearchedStationsNearBy = (lat, lng, zoom_level = 10, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get User from Local Storage
  const user = getState().user;
  var config = prepareFetchingSearchedStations(lat, lng, zoom_level, user);
  dispatch({
    type: FETCH_STATIONS_NEARBY
  });
  return axios(config).then(res => {
    dispatch(saveStationsNearBy(res.data));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchStationOwnerStations = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  dispatch({
    type: FETCH_STATION_OWNER_STATIONS
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/my-stations/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveStationOwnerStations(res.data));
    success_callback(res.data.count);
  }).catch(error => {
    error_callback();
  });
};
export const fetchStationOwnerPaginatedStations = (pageNum, pageSize, search_field, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  dispatch({
    type: FETCH_STATION_OWNER_STATIONS
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: "get",
    url: `${API_URL}/api/stations/my-stations/?page=${pageNum}&page_size=${pageSize}&search=${search_field}`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveStationOwnerStations(res.data));
    success_callback(res.data.count);
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} stations 
 */

export const saveStationOwnerStations = stations => {
  return {
    type: SAVE_STATION_OWNER_STATIONS,
    stations
  };
};
export const fetchStationTransactions = (station_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  dispatch({
    type: FETCH_STATION_TRANSACTIONS
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/${station_id}/transactions/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveStationTransactions(res.data.results));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} transactions 
 */

export const saveStationTransactions = transactions => {
  return {
    type: SAVE_STATION_TRANSACTIONS,
    transactions
  };
};
export const fetchStationChargingPoints = (station_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  dispatch({
    type: FETCH_STATION_CHARGING_POINTS
  }); // Get user Auth Token

  const token = getState().user.token; // Request Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/${station_id}/charging-points/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveStationChargingPoints(res.data));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} charging_points
 */

export const saveStationChargingPoints = charging_points => {
  return {
    type: SAVE_STATION_CHARGING_POINTS,
    charging_points
  };
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchStationOwnerAnalytics = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  dispatch({
    type: FETCH_STATION_OWNER_ANALYTICS
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/analytics-overview/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveStationOwnerAnalytics(res.data));
    success_callback();
  }).catch(error => {
    error_callback();
  });
};
/**
 * 
 * @param {*} stations 
 */

export const saveStationOwnerAnalytics = stations => {
  return {
    type: SAVE_STATION_OWNER_ANALYTICS,
    stations
  };
};
/**
 * 
 * @param {*} station 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const updateStation = (station, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'put',
    url: `${API_URL}/api/stations/${station.id}/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: station
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: UPDATE_STATION,
      station
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {String} station_id 
 * @param {File} image 
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const uploadStationImage = (station_id, image, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var data = new FormData();
  data.append('file', image);
  var config = {
    method: 'post',
    url: `${API_URL}/api/stations/${station_id}/upload-image/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} station_id 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const deleteStation = (station_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'delete',
    url: `${API_URL}/api/stations/${station_id}/`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: DELETE_STATION,
      station_id_to_delete: station_id
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Get station availability / time-schedule
 * 
 * @param {String} station_id 
 * @param {Function} success_callback takes as input the results and executes logic after successful response
 * @param {Function} error_callback 
 * @returns {Promise} response promise from server
 */

export const getStationAvailability = (station_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/availabilities/${station_id}/`,
    headers: {
      'Content-Type': 'application/json'
    }
  }; // If User is Authenticated add token, so that private stations can be fetched as well

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    // Create list of availabilities
    let avList = {};
    res.data.forEach(av => {
      // Get start-end range without seconds (using the substring method)
      avList[av.day] = av.start_time.substring(0, 5) + " - " + av.end_time.substring(0, 5);
    });
    success_callback(avList);
  }).catch(err => {
    error_callback();
  });
};
/**
 * 
 * @param {*} plug 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const updatePlug = (plug, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'put',
    url: `${API_URL}/api/stations/plugs/${plug.id}/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: plug
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: UPDATE_PLUG,
      plug
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * Delete a Plug
 * @param {*} plug Provide whole plug object so that station.id is avaialble as well
 * @param {*} success_callback Function to run after deletion is completed successfully
 * @param {*} error_callback 
 */

export const deletePlug = (plug, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'delete',
    url: `${API_URL}/api/stations/plugs/${plug.id}/`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: DELETE_PLUG,
      plug
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchFeaturedStations = (success_callback = noop, error_callback = noop) => dispatch => {
  dispatch({
    type: FETCH_FEATURED_STATIONS
  });
  return axios.get(`${API_URL}/api/stations/featured-stations/`).then(res => {
    dispatch(saveFeaturedStations(res.data));
    success_callback();
  }).catch(error => {
    if (error.response) {
      dispatch(returnErrors(error.response.data, error.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} stations 
 */

export const saveFeaturedStations = stations => {
  return {
    type: SAVE_FEATURED_STATIONS,
    stations
  };
};
/**
 * Get station object given as input one of its plugs' id.
 * 
 * This action does not return the result in the Redux State.
 * The station object is passed in the success_callback function.
 * 
 * Example usage:
 *    getStationByPlugId(reservation.plug.id, (station) => {
 *      console.log("Station by plug id", station)
 *    })
 * 
 * @param {String} plug_id 
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const getStationByPlugId = (plug_id, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/plugs/${plug_id}/`,
    headers: {
      'Content-Type': 'application/json'
    }
  }; // If User is Authenticated add token, so that private stations can be fetched as well

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    // Update request for new config
    const plug = res.data;
    config.url = `${API_URL}/api/stations/${plug.station.id}/`;
    axios(config).then(res => {
      const station = res.data;
      success_callback(station);
    }).catch(error => {
      handleError(error, error_callback);
    });
  }).catch(error => {
    handleError(error, error_callback);
  });
};
/**
 * Get station object given as input it's slug.
 * 
 * This action does not return the result in the Redux State.
 * The station object is passed in the success_callback function.
 * 
 * Example usage:
 *    getStationBySlug(station_slug, (station) => {
 *      console.log("Station by slug", station)
 *    })
 * 
 * @param {String} slug 
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const getStationBySlug = (slug, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/get-station-by-slug/${slug}/`,
    headers: {
      "Content-type": "application/json"
    }
  }; // If User is Authenticated add token, so that private stations can be fetched as well

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    const station = res.data;
    success_callback(station);
  }).catch(error => {
    dispatch(handleError(error, error_callback)); // error_callback()
  });
};
/**
 * Get station object given as input it's UUID.
 * 
 * This action does not return the result in the Redux State.
 * The station object is passed in the success_callback function.
 * 
 * Example usage:
 *    getStationById(station_slug, (station) => {
 *      console.log("Station by ID", station)
 *    })
 * 
 * @param {String} stationId 
 * @param {Function} success_callback 
 * @param {Function} error_callback 
 */

export const getStationById = (stationId, success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  const user = getState().user;
  var config = {
    method: 'get',
    url: `${API_URL}/api/stations/${stationId}/`,
    headers: {
      "Content-type": "application/json"
    }
  }; // If User is Authenticated add token, so that private stations can be fetched as well

  if (user.token && user.token.access) {
    config.headers['Authorization'] = "Bearer " + user.token.access;
  }

  return axios(config).then(res => {
    const station = res.data;
    success_callback(station);
  }).catch(error => {
    // dispatch(handleError(error, error_callback))
    error_callback();
  });
};