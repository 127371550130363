/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import MuiLink from '@material-ui/core/Link';


const useStyles = makeStyles(theme => ({
  nakedLink: {
    textDecoration: "none"
  },
}));

export const NextLinkComposed = React.forwardRef(function NextComposed(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { href, to, as, replace, scroll, passHref, shallow, prefetch, ...other } = props;

  return (
    <NextLink href={to} as={as}>
      <a ref={ref} {...other} />
    </NextLink>
  );
});

NextLinkComposed.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefetch: PropTypes.bool,
};

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
function Link(props) {
  const classes = useStyles();

  const {
    href,
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href.pathname;
  // See how to combine classNames at https://www.npmjs.com/package/clsx#usage 
  const className = clsx(
    classNameProps, 
    { [activeClassName]: router.pathname === pathname && activeClassName },
    { [classes.nakedLink]: naked }
  );

  if (naked) {
    return (
      <NextLinkComposed 
        className={className} 
        ref={innerRef} 
        to={href} 
        {...other}
      />
    )
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      className={className}
      ref={innerRef}
      to={href}
      {...other}
    />
  );
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  naked: PropTypes.bool,
  onClick: PropTypes.func,
  prefetch: PropTypes.bool,
};

export default React.forwardRef((props, ref) => <Link {...props} innerRef={ref} />);
