import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f18d0f",
      contrastText: "#fff" //button text white instead of black
    },
    secondary: {
      main: "#616161",
    },
    available: {
      main: "#32CD32",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default theme;