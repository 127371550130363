import { CREATE_USER_VEHICLE, UPDATE_USER_VEHICLE, FETCH_USER_VEHICLES, SAVE_USER_VEHICLES, FETCH_VEHICLE_MODELS, SAVE_VEHICLE_MODELS } from '../actions/vehicleActions';
export const initialState = {
  user_vehicles: [],
  vehicle_models: []
};

const vehicles = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_VEHICLE:
    case UPDATE_USER_VEHICLE:
    case FETCH_USER_VEHICLES:
      return state;

    case FETCH_VEHICLE_MODELS:
      return state;

    case SAVE_USER_VEHICLES:
      if (state.user_vehicles === action.vehicles.results) return state;else return { ...state,
        user_vehicles: action.vehicles.results
      };

    case SAVE_VEHICLE_MODELS:
      return { ...state,
        vehicle_models: action.models.results
      };

    default:
      return state;
  }
};

export default vehicles;