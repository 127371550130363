import { fetchSearchedStations } from './stationActions';
/**
 * Define Actions
 */

export const SUBMIT_SEARCH_QUERY = 'SUBMIT_SEARCH_QUERY';
export const UPDATE_FOCUSED_LOCATION = 'UPDATE_FOCUSED_LOCATION';
export const UPDATE_ZOOM_ONLY = 'UPDATE_ZOOM_ONLY';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
/**
 * Define Action Creators
 */

/**
 * 
 * @param {String} search_query 
 * @returns 
 */

export const submitSearchQuery = search_query => dispatch => {
  dispatch({
    type: SUBMIT_SEARCH_QUERY,
    payload: search_query
  });
};
/**
 * Update location and also fetch the stations centered there
 * @param {Number} lat 
 * @param {Number} lng 
 * @param {Number} zoom_level 
 * @returns 
 */

export const updateFocusedLocation = (lat, lng, zoom_level = 10) => dispatch => {
  dispatch({
    type: UPDATE_FOCUSED_LOCATION,
    lat,
    lng,
    zoom: zoom_level
  });
  dispatch(fetchSearchedStations(lat, lng, zoom_level));
};
/**
 * Update location only without fetching any stations
 * @param {Number} lat 
 * @param {Number} lng 
 * @param {Number} zoom_level 
 * @returns 
 */

export const updateFocusedLocationOnly = (lat, lng, zoom_level = 10) => dispatch => {
  dispatch({
    type: UPDATE_FOCUSED_LOCATION,
    lat,
    lng,
    zoom: zoom_level
  });
};
/**
 * 
 * @param {Number} zoom_level 
 * @returns 
 */

export const updateZoomOnly = zoom_level => dispatch => {
  dispatch({
    type: UPDATE_ZOOM_ONLY,
    zoom: zoom_level
  });
};
export const getSearchResults = () => {
  return {
    type: GET_SEARCH_RESULTS
  };
};
export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH
  };
};