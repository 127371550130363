import { createStore, applyMiddleware, compose } from 'redux'
import reducer, { initialState } from './js-shared/lib/reducers'
import thunk from 'redux-thunk';
import { loadState, saveState } from './js-shared/lib/config/storage';
import merge from 'lodash/merge'
import LogRocket from "logrocket"
import { getLogrocketReduxMiddleware } from './js-shared/lib/utils/logrocket';


// Compose middleware
var middleware = null;
if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(thunk)
} else {
  middleware = compose(
    applyMiddleware(
      thunk,
      getLogrocketReduxMiddleware(LogRocket)
    ),
    // Load redux dev tools if browser extension is installed and in development environment
    (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__() : (a) => a
  )
}

// Merge initial Redux state with current state (if any), so that if 
// the redux schema changes we also load the new state-schema changes!
// THis only works if we append to the schema, if we remove a property from
// the schema it will remain unless we clean the localStorage
// PROBLEMATIC
// const persistedState = merge(initialState, loadState())

let persistedState;
if (typeof window !== 'undefined') {
  persistedState = loadState()
} 
if (typeof window === 'undefined') {
  persistedState = initialState;
}

const store = createStore(
  reducer,
  persistedState,
  middleware
)

if (typeof window !== 'undefined') {
  store.subscribe(() => {
    saveState(store.getState());
  })
}

export default store
