import { START_OCPP_TRANSACTION, STOP_OCPP_TRANSACTION, GET_PLUG_OCPP_STATUS, GET_METER_VALUES, GET_TOTAL_KWH } from '../actions/ocppActions';
export const initialState = {
  stations_plug_status: []
};

const ocpp = (state = initialState, action) => {
  switch (action.type) {
    case START_OCPP_TRANSACTION:
    case STOP_OCPP_TRANSACTION:
    case GET_PLUG_OCPP_STATUS:
      return { ...state,
        stations_plug_status: action.initialState
      };

    case GET_METER_VALUES:
      return { ...state,
        stations_plug_status: state.stations_plug_status.map(plug => {
          if (plug.id == action.plug_id) {
            return { ...plug,
              meter_value: action.data
            };
          } else return { ...plug
          };
        })
      };

    case GET_TOTAL_KWH:
      return { ...state,
        stations_plug_status: state.stations_plug_status.map(plug => {
          if (plug.id == action.plug_id) {
            return { ...plug,
              total_kwh: action.total_kwh
            };
          } else return { ...plug
          };
        })
      };

    default:
      return state;
  }
};

export default ocpp;