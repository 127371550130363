import { FETCH_USER_NOTIFICATIONS, FETCHED_USER_NOTIFICATIONS, FETCH_USER_NOTIFICATIONS_FAIL } from "../actions/notificationActions";
export const initialState = {
  user_notifications: []
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_NOTIFICATIONS:
      return state;

    case FETCHED_USER_NOTIFICATIONS:
      return { ...state,
        user_notifications: action.notifications.results
      };

    default:
      return state;
  }
};

export default notifications;