/**
 * Format price
 * @param Object object 
 * @returns {String} formatted price string
 */
export const formatPrice = ({
  amount,
  currency,
  quantity
}) => {
  // const numberFormat = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency,
  //   currencyDisplay: 'symbol',
  // });
  // const total = (quantity * amount).toFixed(2);
  // return numberFormat.format(total);
  const total = (quantity * amount).toFixed(2);

  switch (currency) {
    case 'EUR':
      return `€${total}`;

    case 'USD':
    default:
      return `$${total}`;
  }
};
/**
 * Keep only 2 decimals of given number without doing any rounding
 * @param {string|number} number 
 * @returns {Number} formatted number 
 */

export function keep2Decimals(number) {
  // Make number a float if not already float
  number = +number;
  const a = number.toFixed(3).split(".");
  const b = `${a[0]}.${a[1]?.slice(0, 2)}`;
  const c = +b;
  return c;
}
/**
 * Return string with plug's pricing policy
 * e.g. 1€ + 0.10€/min
 * @param {Object} plug 
 * @returns {String} pricing string
 */

export function getPlugPricingString(plug) {
  const a = keep2Decimals(plug.base_flat_fee);
  let b = 0;
  const c = "€";

  switch (plug.pricing_policy) {
    case 0:
      b = keep2Decimals(plug.price_per_kWh).toFixed(2);

      if (a !== 0) {
        return `${a}${c} + ${c}${b}/kWh`;
      } else {
        return `${c}${b}/kWh`;
      }

    case 1:
      b = keep2Decimals(plug.base_price);
      return `${a}${c} + ${b}${c}/min`;
  } // TODO: Add currency
  // const c = plug.pricing_currency
  // const pricing = `${a}${c} + ${b}${c}/min`;
  // return pricing;

}