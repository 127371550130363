import { refreshUserToken } from './userActions';
/**
 * Define Actions
 */

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
/**
 * Define Action Creators
 */
//  Return & Handle errors

export const returnErrors = (msg, status, id) => dispatch => {
  dispatch({
    type: GET_ERRORS,
    payload: {
      msg,
      status,
      id
    }
  }); // Handle errors

  if (msg) {
    if (msg.code === "token_not_valid") {
      dispatch(refreshUserToken());
      dispatch(clearErrors());
    }
  }
}; // Clear Errors

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
/**
 * Handle XHR error case by dispatching redux error actions and
 * then running the error_callback passed in the function's arguments
 * @param {Object} err error object returned from axios
 * @param {Function} error_callback 
 */

export const handleError = (err, error_callback) => dispatch => {
  if (err.response) {
    dispatch(returnErrors(err.response.data, err.response.status));
  } else {
    dispatch(returnErrors("No response from server", null));
  }

  error_callback();
};