export const STORAGE_BACKEND = "LOCAL_STORAGE";
var storage;

if (typeof window !== 'undefined') {
  storage = window.localStorage;
}

export default storage;
export const getFromStorage = objName => {
  try {
    if (typeof window !== 'undefined') {
      return storage.getItem(objName);
    } else return undefined;
  } catch (e) {}
};
export const saveToStorage = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);

    if (typeof window !== 'undefined') {
      storage.setItem(key, serializedValue);
    }
  } catch (e) {// 
  }
};
export const loadState = () => {
  // 
  try {
    const serializedState = getFromStorage('state');

    if (serializedState === null) {
      // 
      return undefined;
    }

    const state = JSON.parse(serializedState); // 

    return state;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
export const saveState = state => {
  try {
    saveToStorage('state', state);
  } catch (err) {}
};