import { GET_BALANCE, GET_USER_TRANSACTIONS, GET_TOKEN_PRICE, GET_STATION_OWNER_TRANSACTIONS } from '../actions/walletActions';
export const initialState = {
  driver_tokens_quantity: 0,
  station_owner_tokens_quantity: 0,
  transactions: [],
  station_owner_transactions: [],
  token_fiat_price: 0.1
};

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE:
      let driver_tokens = action.payload.driver_tokens_quantity;
      if (driver_tokens < 0) driver_tokens = 0;
      return { ...state,
        driver_tokens_quantity: driver_tokens,
        station_owner_tokens_quantity: action.payload.station_owner_tokens_quantity
      };

    case GET_USER_TRANSACTIONS:
      return { ...state,
        transactions: action.payload
      };

    case GET_STATION_OWNER_TRANSACTIONS:
      return { ...state,
        station_owner_transactions: action.payload
      };

    case GET_TOKEN_PRICE:
      return { ...state,
        token_fiat_price: action.payload
      };

    default:
      return state;
  }
};

export default wallet;