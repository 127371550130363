import axios from "axios";
import { API_URL, APP_ENV, ENVIRONMENT } from "../config/environment";
import { returnErrors, clearErrors, GET_ERRORS } from "./errorActions";
import qs from "qs";

const noop = () => {};

export const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";
export const FETCHED_USER_NOTIFICATIONS = "FETCHED_USER_NOTIFICATIONS";
export const FETCH_USER_NOTIFICATIONS_FAIL = "FETCH_USER_NOTIFICATIONS_FAIL";
/**
 * Check token & load user notifications
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const fetchUserNotifications = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // User loading
  dispatch({
    type: FETCH_USER_NOTIFICATIONS
  }); // Get token from localStorage

  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/my-notifications/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch(saveUserNotifications(res.data));
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    dispatch({
      type: FETCH_USER_NOTIFICATIONS_FAIL
    });
    error_callback();
  });
};
/**
 * 
 * @param {*} vehicles
 */

export const saveUserNotifications = notifications => {
  return {
    type: FETCHED_USER_NOTIFICATIONS,
    notifications
  };
};