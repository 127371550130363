import { useState, useEffect } from "react";


/**
 * Try to detect device type (mobile or desktop) and operating system (android, ios).
 * Based on this solution https://vhudyma-blog.eu/detect-mobile-device-in-react/ 
 * @returns User agent details and booleans based on detected os
 */
export default function useOperatingSystemDetection() {
  const [isDesktop, setIsDesktop] = useState(true) // use desktop as default
  const [isMobile, setIsMobile] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)
  const [isApple, setIsApple] = useState(false)
  const [isIos, setIsIos] = useState(false)
  const [osName, setOsName] = useState(null)
  const [userAgent, setUserAgent] = useState(null)

  // First useEffect to check if device is desktop or mobile
  useEffect(() => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    setUserAgent(ua)


    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setIsMobile(true);
      setIsDesktop(false);
    } else {
      setIsMobile(false);
      setIsDesktop(true);
    }
  }, []);

  useEffect(() => {
    if (/android/i.test(userAgent)) {
      setIsAndroid(true)
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // Do not test for Apple because AppleWebKit exists in both iOS Safari as well as all Chrome browsers
    if (/iPad|iPhone|iPod|Macintosh|Mac|OS X/.test(userAgent) && !window.MSStream) {
      setIsApple(true)
    }
  }, [userAgent])

  useEffect(() => {
    if (isApple && isMobile) {
      setIsIos(true)
    } else {
      setIsIos(false)
    }
  }, [isApple, isMobile])

  return { isDesktop, isMobile, isAndroid, isApple, isIos, osName, userAgent };
}
