import { combineReducers } from "redux";
import user, { initialState as userInitialState } from "./userReducer";
import stations, { initialState as stationsInitialState } from "./stationsReducer";
import reservations, { initialState as reservationsInitialState } from "./reservationsReducer";
import wallet, { initialState as walletInitialState } from "./walletReducer";
import search, { initialState as searchInitialState } from "./searchReducer";
import errors, { initialState as errorsInitialState } from "./errorsReducer";
import ocpp, { initialState as ocppInitialState } from "./ocppReducer";
import config, { initialState as configInitialState } from "./configReducer";
import vehicles, { initialState as vehiclesInitialState } from "./vehiclesReducer";
import energy, { initialState as energyInitialState } from "./energyReducer";
import notifications, { initialState as notificationsInitialState } from "./notificationReducer";
import { LOGOUT_SUCCESS } from '../actions/userActions';
const appReducer = combineReducers({
  user,
  stations,
  reservations,
  wallet,
  search,
  errors,
  config,
  ocpp,
  vehicles,
  energy,
  notifications,
  action: (state = null, _action) => _action.type
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = { ...state,
      stations: stationsInitialState,
      reservations: reservationsInitialState,
      wallet: walletInitialState,
      vehicles: vehiclesInitialState,
      energy: energyInitialState,
      notifications: notificationsInitialState
    };
  }

  return appReducer(state, action);
};

export const initialState = {
  user: userInitialState,
  stations: stationsInitialState,
  reservations: reservationsInitialState,
  wallet: walletInitialState,
  search: searchInitialState,
  errors: errorsInitialState,
  config: configInitialState,
  ocpp: ocppInitialState,
  vehicles: vehiclesInitialState,
  energy: energyInitialState,
  notifications: notificationsInitialState
};
export default rootReducer;