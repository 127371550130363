import axios from 'axios';
import { API_URL } from '../config/environment';
import { returnErrors } from './errorActions';

const noop = () => {};
/**
 * Define Actions
 */


export const GET_BALANCE = 'GET_BALANCE';
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS';
export const GET_STATION_OWNER_TRANSACTIONS = 'GET_STATION_OWNER_TRANSACTIONS';
export const GET_TOKEN_PRICE = 'GET_TOKEN_PRICE';
/**
 * Define Action Creators
 */

/**
 * Get user's (driver) Balance in tokens
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getBalance = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/wallet/my-balance/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_BALANCE,
      payload: res.data.results[0]
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getStationOwnerTransactions = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/wallet/station-owner-transactions/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_STATION_OWNER_TRANSACTIONS,
      payload: res.data.results
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getUserTransactions = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/wallet/driver-transactions/`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_USER_TRANSACTIONS,
      payload: res.data.results
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};
/**
 * 
 * @param {*} success_callback 
 * @param {*} error_callback 
 */

export const getTokenFiatPrice = (success_callback = noop, error_callback = noop) => (dispatch, getState) => {
  // Get token from localStorage
  const token = getState().user.token; // Headers

  var config = {
    method: 'get',
    url: `${API_URL}/api/wallet/token-price`,
    headers: {
      "Content-type": "application/json"
    }
  };

  if (token) {
    config.headers['Authorization'] = "Bearer " + token.access;
  }

  return axios(config).then(res => {
    dispatch({
      type: GET_TOKEN_PRICE,
      payload: res.data.token_fiat_price
    });
    success_callback();
  }).catch(err => {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    } else {
      dispatch(returnErrors("No response from server", null));
    }

    error_callback();
  });
};